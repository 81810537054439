import React, { Component } from "react";

class AboutTwo extends Component{
    render(){
        let title = 'About',
        description = 'Mercury Branding is a branding, web design, web development marketing and printing agency with a specialization in online tools, automations and strategy. Our proven Brand, Build, Grow process creates the online foundation that businesses need for lasting marketing success. First we develop an impactful BRAND to attract your ideal customers. Second, we BUILD an effective website that is fully integrated with the right online tools and marketing automations. Finally, we help you GROW by giving you the on-going support, online tools, and strategy to grow your business like never before!';
        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">

                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about-3.jpg" alt="About Images"/>
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">{title}</h2>
                                        <p className="description">{description}</p>
                                    </div>
                                  {/*   <div className="row mt--30 mt_sm--10">
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">Who we are</h3>
                                                <p>There are many vtions of passages of Lorem Ipsum available, but the majority have suffered.</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">Who we are</h3>
                                                <p>There are many vtions of passages of Lorem Ipsum available, but the majority have suffered.</p>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default AboutTwo;
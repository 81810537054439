import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";


class ServiceDetails extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                
                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Service Details' />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--5"  data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">WEBSITE DEVELOPMENT</h2>
                                    <p>Fresh From The Press Discription</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/service-01.png" alt="Service Images"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                <h4 className="title">Advertising</h4>
                                                    <p>Mercury Branding is a full-service advertising agency that specializes in creating and executing campaigns for brands of all sizes. We have a team of experienced professionals who are experts in all things advertising, from strategy and planning to media buying and creative execution. We're passionate about helping our clients reach their marketing goals, and we're always looking for new and innovative ways to get the job done.</p>
                                                    <p>If you're looking for an advertising agency that can help you take your brand to the next level, Mercury is the perfect partner. We'll work with you to create a customized campaign that targets your specific audience and achieves your desired results. Contact us today to learn more about what we can do for you.</p>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                <h4 className="title">Coding</h4>
                                                    <p>If you're running a business, you know that image is important. Your website is one of the first places potential customers will go to learn about your company, and first impressions matter. That's where a Mercury Branding can come in handy. Mercury Branding can help you design and develop a website that not only looks professional, but also functions well.</p>
                                                    <p>Looking for a top-notch coding agency, look no further than Mercury Branding. We have a team of experienced coders who can take your project from concept to completion. We're also proud to offer competitive pricing and quick turnaround times. Contact us today to learn more about what we can do for you!</p>
                                        
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 order-1 order-lg-2">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/service/service-02.png" alt="Service Images"/>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        <br /> <br /><br /> 
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/service-01.png" alt="Service Images"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                     <h4 className="title">Design</h4>
                                                    <p>If you're looking for a design agency that can help you create a powerful and effective brand, then look no further than Mercury Branding. We specialize in helping businesses of all sizes develop a strong identity that will resonate with their target audiences. We'll work with you to understand your unique needs and objectives, and then we'll create a custom branding strategy that will help you achieve your goals. We offer a wide range of services, including logo design, brand development, and marketing collateral creation. So whether you're just starting out or you're ready to take your brand to the next level, we can help. Contact us today to learn more about what we can do for you.</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                <h4 className="title"> Marketing</h4>
                                                    <p>If you're looking for a marketing agency that can help take your business to the next level, look no further than Mercury Branding. We specialize in helping businesses create and execute marketing campaigns that get results. Whether you're looking to reach a new audience or grow your existing customer base, we can help. We offer a full suite of marketing services, from brand strategy and identity development to digital marketing and public relations. We also offer web design and development services. In other words, we can help with every aspect of your marketing, from start to finish.</p>
                                                    <p>What sets us apart from other agencies is our commitment to results. We don't just want to create pretty campaigns; we want to create campaigns that deliver results. That's why we work closely with our clients to understand their business goals and develop strategies that will help them achieve those goals.</p>                                               
                                                    <p>If you're ready to take your marketing to the next level, contact us today. </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 order-1 order-lg-2">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/service/service-02.png" alt="Service Images"/>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        <br /> <br /><br /> 
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/service-01.png" alt="Service Images"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                <h4 className="title">Printing</h4>
                                                    <p>Mercury Branding is a printing agency that specializes in creating high-quality prints for businesses of all sizes. We understand the importance of branding and how it can impact your business. That’s why we offer a wide range of printing services that are designed to help you promote your brand in the most effective way possible.</p>
                                                    <p>From business cards and brochures to signage and promotional materials, we can print anything you need to help get your brand out there. We also offer a wide range of customization options so you can create prints that are truly unique to your brand.</p>
                                                    <p>Contact us today to learn more about our services and how we can help you promote your business.</p>                                                    
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                <h4 className="title">Branding</h4>
                                                    <p>If you're like most people, the word "branding" probably conjures up images of big companies with huge budgets and teams of marketing experts. But the truth is, branding is something that any business, no matter its size or budget, can benefit from. And it doesn't have to be complicated or expensive.</p>
                                                    <p>As a branding agency, Mercury Branding strives to create unique and memorable brands for our clients. We understand that a strong brand can be the difference between success and failure in today’s competitive marketplace. That’s why we take the time to get to know our clients and their businesses before we begin the branding process. Once we have a clear understanding of our client’s goals, values, and target audience, we can begin developing a brand that will resonate with your customers. We believe that every brand has a story to tell, and we strive to tell that story in a way that is both creative and memorable.</p>
                                                    <p>Contact us today. We’d be happy to discuss your branding goals and how we can help you achieve them.</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 order-1 order-lg-2">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/service/service-02.png" alt="Service Images"/>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}
                
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />

            </React.Fragment>
        )
    }
}
export default ServiceDetails;
import React ,{ Component }from "react";
import Icon1 from '../../../public/assets/images/icons/icon1.png';
import Icon2 from '../../../public/assets/images/icons/icon2.png';
import Icon3 from '../../../public/assets/images/icons/icon3.png';
import Icon4 from '../../../public/assets/images/icons/icon4.png';
import Icon5 from '../../../public/assets/images/icons/icon5.png';
import Icon6 from '../../../public/assets/images/icons/icon6.png';

const ServiceList = [
    {
        icon:<img alt="" src={Icon3} /> ,
        title: 'Advertising',
        description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    },
    {
        icon: <img alt="" src={Icon2} />,
        title: 'Development',
        description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    },
    {
        icon: <img alt="" src={Icon1} />,
        title: 'Design',
        description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    },
    {
        icon: <img alt="" src={Icon5} />,
        title: 'Marketing',
        description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    },
    {
        icon: <img alt="" src={Icon4} />,
        title: 'Printing',
        description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    },
    {
        icon: <img alt="" src={Icon6} />,
        title: 'Branding',
        description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    },
]


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            <a href="/service">
                                <div className="service service__style--2">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                      {/*   <p>{val.description}</p> */}
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
